
import './input.css';
import CharacterList from './Character/CharacterList';

function App() {
  return (
    <CharacterList />
  );
}

export default App;
